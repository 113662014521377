@import "../../theme/variables";
@import "../../theme/functions/grid";
@import "../../theme/functions/screen";
@import "../../theme/keyframes/fadeInDown";
@import "../../theme/mixins/scrollbar";

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  visibility: hidden;
  padding: grid(3);
  background-color: $color-bg-backdrop;
  opacity: 0;
  transition: opacity $animation-speed-fast ease-in-out, visibility $animation-speed-fast ease-in-out;

  backdrop-filter: blur(4px);

  &--open {
    visibility: visible;
    opacity: 1;

    .modal {
      animation: fadeInDown 500ms;
    }
  }
}

.modal {
  width: grid(100);
  border-radius: $radius-medium;
  background-color: $color-bg-primary;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);

  &--fullscreen {
    flex: 1;
    max-width: screen(800);
    width: 100%;
  }
}

.body {
  @include scrollbar();

  overflow-y: auto;
  padding: grid(3) grid(6);
  max-height: 80vh;

  &--without-padding {
    padding: 0;
  }
}

.header {
  padding: 0 0 0 grid(6);
  border-bottom: 1px solid $color-bg-divider;
  background-color: $color-bg-light;
  color: $color-text-darker;
  font-weight: 200;
  font-size: $font-size-large;
}

.close-icon {
  flex-shrink: 0;
  padding: 0;
  padding: grid(3);
  width: grid(10);
  height: grid(10);
  cursor: pointer;
}

.modal-actions {
  border-top: 1px solid $color-bg-divider;
}

.modal-button {
  padding: grid(3) 0;
  height: grid(10);
  cursor: pointer;
  transition: background-color $animation-speed-medium ease-in-out;

  &--danger {
    color: $color-bg-danger;
  }

  &--call-to-action {
    color: $color-text-tertiary;
  }

	&--disabled {
    background-color: $color-bg-divider !important;
    color: $color-text-primary !important;
    cursor: not-allowed !important;
	}

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.primary {
  color: #fff;
  background-color: $color-brand;
  transition: background-color $animation-speed-medium ease-in-out;

  &:hover {
    background-color: #f781b1;
  }
}

.loading-wrap {
  height: grid(10);
}

.divider {
  flex: 1 0 1;
  width: 1px;
  background-color: $color-bg-divider;
}

@import "../../theme/variables";
@import "../../theme/functions/grid";
@import "../../theme/functions/screen";
@import "../../theme/keyframes/stretchVertical";

.menu {
  overflow-x: hidden;
  overflow-y: visible;
  padding: 0;
  height: 100%;

  scrollbar-color: $color-bg-tertiary $color-bg-primary;
  scrollbar-width: thin;
}

.group {
  margin-top: grid(3);
  padding: 0 grid(3);
  text-transform: uppercase;
  font-weight: 100;
  font-size: $font-size-small;

  &:first-child {
    margin-top: 0;
  }
}


.item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: grid(1) 0;
  padding: 0 grid(2);
  height: grid(4);
  color: $color-text-primary;
  text-decoration: none;
  white-space: nowrap;
  font-weight: 400;
  font-size: $font-size-medium;
  cursor: pointer;

  &:after {
    position: absolute;
    top: 0;
    right: screen(-2.5);
    bottom: 0;
    display: block;
    width: screen(5);
    border-radius: $radius-infinite;
    background-color: transparent;
    content: "";
    transition: background-color ease-in-out $animation-speed-fast;
  }

  svg {
    margin-right: grid(1);
    height: grid(4);
    fill: $color-text-primary;
  }

  &:hover {
    color: $color-text-darker;

    &:after {
      background-color: $color-bg-divider;
      animation: stretchVertical 500ms forwards;
    }
  }

  &--active {
    // background-color: rgba(0, 0, 0, 0.03);
    color: $color-text-darker;

    &:after {
      background-color: $color-bg-tertiary !important;
      animation: stretchVertical 500ms forwards;
    }

    svg {
      fill: $color-bg-tertiary;
    }
  }
  &--subtitle {
    // background-color: rgba(0, 0, 0, 0.03);
    color: $color-text-lighter;
    font-size: $font-size-small;

    svg {
      fill: $color-text-darker;
    }
  }
}

@import "../../theme/variables";
@import "../../theme/functions/grid";

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: grid(3);
  background-color: $color-bg-backdrop;

  backdrop-filter: blur(4px);
}

.changelog {
  max-width: 800px;
  border-radius: $radius-medium;
  background-color: $color-bg-primary;
}

.title {
  padding: 0 0 0 grid(3);
  border-bottom: 1px solid $color-bg-divider;
  background-color: $color-bg-light;
  font-size: $font-size-large;
}

.close-icon-wrap {
  padding: grid(3);
}

.close-icon {
  width: grid(3);
  height: grid(3);
  fill: $color-text-primary;
}

.content {
  padding: grid(3);
}

.item {
  margin-bottom: grid(1);
}

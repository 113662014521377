@import "../../theme/variables";
@import "../../theme/mixins/shake";
@import "../../theme/functions/grid";

.label {
  margin-bottom: grid(1);
  color: $color-text-primary;
  font-size: $font-size-tiny;
}

.error {
  display: flex;
  flex-direction: column;
  min-height: $field-error-height;
  color: $color-error;
  font-size: $font-size-small;
  opacity: 0;
  transition: opacity $animation-speed-medium ease-in-out;
}

@import "../../theme/functions/grid";
@import "../../theme/variables";

.table {
  display: table;
  overflow: visible;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

.tr {
  display: table-row;

  &--highlight {
    background-color: $color-bg-light;
  }

  &:hover td {
    background-color: #fcfcfc;
  }
}

.th,
.td {
  display: table-cell;
  padding: grid(1) grid(2);
  vertical-align: middle;
  text-align: left;
  &--word-break {
    word-break: break-word;
  }
  &--color-red {
    color: #ff0000;
  }
  &--strike-through {
    text-decoration: line-through;
  }
}

.th {
  position: sticky;
  top: 0;
  z-index: 2;
  display: table-cell;
  padding: grid(2) grid(2);
  border-bottom: 1px solid $color-bg-divider;
  background-color: $color-bg-primary;
  font-weight: 500;
  font-size: $font-size-small;
  &--compact {
    width: 1%;
    white-space: nowrap;
  }
}

.th,
.td {
  overflow: visible;

  &--center {
    text-align: center;
  }
}

.td {
  display: table-cell;
  border-bottom: 1px solid $color-bg-divider;
  font-weight: 300;
  font-size: $font-size-small;
}

@import "../../theme/functions/grid";

.version {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  padding: grid(0.5);
  color: #cccccc;
  font-size: grid(1.5);
}

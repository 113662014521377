@import "../../theme/variables";

.icon {
  padding-right: 8px;
  width: auto;
  height: 1em;
  color: $color-text-primary;
}

.icon-active {
  padding-right: 8px;
  width: auto;
  height: 1em;
  color: $color-text-tertiary;
}

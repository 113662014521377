@import "./constants";
@import "./functions/strip-unit";
@import "./functions/screen";
@import "./functions/grid";

/*
 Reference grid(n) lookup table:
 -  1: 8
 -  2: 16
 -  3: 24
 -  4: 32
 -  5: 40
 -  6: 48
 -  7: 56
 -  8: 64
 -  9: 72
 - 10: 80
 - 11: 88
 - 12: 96
*/

// open /experiments/theme (or Theme story) to visually compare theme colors
// please update components/Theme when adding new colors

// beginning colors
$color-brand: #f774a9;

// text colors
$color-text-primary: #777777;
$color-text-secondary: #ffffff;
$color-text-tertiary: #84b9e2;
$color-text-darker: #303133;
$color-text-lighter: #bbb9b6;

// background colors
$color-bg-primary: #ffffff;
$color-bg-secondary: #404144;
$color-bg-tertiary: #84b9e2;
$color-bg-backdrop: rgba(0, 0, 0, 0.6);
$color-bg-light: #f8f8f8;
$color-bg-divider: #ededed;
$color-bg-danger: #990000;

// special colors
$color-error: #cc0000;

// debug colors
$color-debug-1: rgba(255, 0, 0, 0.1);
$color-debug-2: rgba(0, 255, 0, 0.1);
$color-debug-3: rgba(0, 0, 255, 0.1);

// color adjusting
$color-change-percent: 10%;

// font families
$font-main: "Roboto", sans-serif;;
$font-alternate: "Source Sans Pro", sans-serif;

// font sizes
$font-size-tiny: screen(16px);
$font-size-small: screen(18px);
$font-size-medium: screen(22px);
$font-size-large: screen(32px);

// layout
$layout-view-padding: 6rem;
$layout-limited-width: screen(1920) - $layout-view-padding * 2;

// animations
$animation-speed-fast: 150ms;
$animation-speed-medium: 300ms;
$animation-speed-slow: 500ms;

// border radiuses
$radius-infinite: 1000px;
$radius-medium: screen(10);
$radius-small: screen(5);

// field configuration
$field-input-height: grid(6);
$field-icon-width: grid(3);
$field-icon-margin: grid(1.5);
$field-error-height: grid(4);
$field-label-padding: grid(1.5);
$field-border-size: 1px;
$field-active-label-scale: 0.75;
$field-small-scale: 0.75;

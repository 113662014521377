@import "../../theme/variables";
@import "../../theme/functions/grid";

.header {
  padding: grid(1) grid(2);
  border-bottom: 1px solid $color-bg-divider;
  background-color: $color-bg-primary;
}

.menu {
  position: relative;
  z-index: 3;
  padding-top: grid(2);
  width: grid(35);
  border-right: 1px solid $color-bg-divider;
  background-color: $color-bg-primary;
}

.content {
  background-color: $color-bg-primary;
}

@import "../../theme/variables";
@import "../../theme/functions/grid";
@import "../../theme/mixins/shake";

.field {
  position: relative;
  padding-top: $field-label-padding;
  color: $color-text-primary;
  pointer-events: all;

  &--has-value,
  &--has-focus {
    .label {
      color: $color-text-darker;
      transform: translateY(-$field-input-height * 0.5) translateX(0) scale($field-active-label-scale);
    }

    .border {
      background-color: $color-text-darker;
    }
  }

  &--has-value {
    .value {
      opacity: 1;
    }

    .clear-icon {
      opacity: 1;
    }
  }

  &--has-error {
    @include shake();

    .border {
      background-color: $color-error !important;
    }

    .error {
      opacity: 1;
    }
  }

  &--file {
    input {
      opacity: 0;
    }
  }

  // move the absolutely positioned label over the leading icon
  &--has-leading .label {
    left: $field-icon-width + $field-icon-margin;
  }

  // added when requiresActivation prop is provided
  &--deactivated {
    // make deactivated field border transparent
    .border {
      background-color: change-color($color: $color-text-primary, $alpha: 0.0);
    }

    .label {
      text-decoration: underline;
    }
  }
}

// border is separate element to be able to scale it for border thickness animation on focus
.border {
  width: 100%;
  height: $field-border-size;
  background-color: $color-text-primary;
  transition: background-color $animation-speed-medium ease-in-out, transform $animation-speed-medium ease-in-out;

  &--transparent {
    opacity: 0;
  }
}

.icon {
  justify-items: flex-end;
  align-items: center;
  width: $field-icon-width + $field-icon-margin;
  height: $field-input-height;

  svg {
    fill: $color-text-darker;
  }

  &--leading {
    justify-content: flex-start;
    padding-right: $field-icon-margin;
  }

  &--trailing {
    justify-content: flex-end;
    padding-left: $field-icon-margin;
  }

  &--expand-trailing {
    width: $field-icon-width * 2 + $field-icon-margin + grid(2);
  }

  // make children use all available space
  > * {
    width: 100%;
    height: 100%;
  }
}

.label {
  position: absolute;
  top: $field-label-padding;
  left: 0;
  display: flex;
  align-items: center;
  height: $field-input-height;
  color: $color-text-primary;
  font-size: $font-size-small;
  transition: transform $animation-speed-medium ease-in-out, color $animation-speed-medium ease-in-out;
  transform: translateY(0);
  transform-origin: center left;
  span {
    color: red;
    padding-left: 8px;
  }
}

.input {
  padding: 0;
  width: 100%;
  height: $field-input-height;
  outline: none;
  border: none;
  background: none;
  color: $color-text-darker;
  font-size: $font-size-small;
}

.error {
  display: flex;
  flex-direction: column;
  min-height: $field-error-height;
  color: $color-error;
  font-size: $font-size-small;
  opacity: 0;
  transition: opacity $animation-speed-medium ease-in-out;
}

.toggle-password {
  opacity: 0;
  transition: opacity $animation-speed-medium ease-in-out !important;

  &--visible {
    opacity: 1;
  }
}

.clear-icon {
  opacity: 0;
  cursor: pointer;
  transition: opacity $animation-speed-fast ease-in-out !important;
}

@import "../../theme/variables";
@import "../../theme/mixins/shake";
@import "../../theme/functions/grid";

.textarea {
  // make border darker on value and focus
  &--has-value .border,
  &--has-focus .border {
    background-color: $color-text-darker;
  }

  &--has-value .label {
    border: 1px solid green;
  }

  &--has-error {
    @include shake();

    .border {
      background-color: $color-error !important;
    }

    .error {
      opacity: 1;
    }
  }
}

.input {
  padding: 0;
  width: 100%;
  border: 0;
  background-color: transparent;
  color: $color-text-darker;
  font-size: $font-size-small;
  resize: none;

  &:focus {
    outline: 0;
  }
}

// border is separate element to be able to scale it for border thickness animation on focus
.border {
  width: 100%;
  height: $field-border-size;
  background-color: $color-text-primary;
  transition: background-color ease-in-out $animation-speed-medium, transform ease-in-out $animation-speed-medium;
}

.label {
  margin-bottom: grid(1);
  color: $color-text-primary;
  font-size: $font-size-tiny;
  span {
    color: red;
    padding-left: 8px;
  }
}

.error {
  display: flex;
  flex-direction: column;
  min-height: $field-error-height;
  color: $color-error;
  font-size: $font-size-small;
  opacity: 0;
  transition: opacity $animation-speed-medium ease-in-out;
}
